import React, { useState } from "react";
import { differenceInDays, parseISO } from "date-fns";
import "./App.css";

function App() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const calculateDifference = () => {
    if (!startDate || !endDate) {
      return "";
    }
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    return differenceInDays(end, start);
  };

  return (
    <div className="App">
      <h1>Date Difference Calculator</h1>
      <p>
        Enter two dates to calculate the difference in days. Dates can be
        selected by clicking on the calendar icon or they can be entered
        manually.
      </p>
      <div>
        <label>Start Date: </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <div>
        <label>End Date: </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <div>
        <strong>Difference in days:</strong> {calculateDifference()}
      </div>
    </div>
  );
}

export default App;
